<template>
    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3 class="">Login to your account</h3>
        </div>
        <!--begin::Multi Authenticator Form-->
        <b-form class="kt-form" v-if="multiAuthenticator">
          <div role="alert" class="alert alert-danger" v-if="errors.length > 0">
            <div class="alert-text" v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>
          <b-form-group
            id="example-input-group-1"
            label-for="example-input-1"
            v-if="!verificationEmailBtn"
          >
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              @keypress.enter.native.prevent
              placeholder="Google Verification Code"
              v-model="authenticator.google.verification_code"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">Verification Code</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label label-for="email-verification-code" v-if="verificationEmailBtn">
            <b-form-input
              name="email-verification-code"
              @keypress.enter.native.prevent
              placeholder="Email Verification Code"
              v-model="authenticator.email.verification_code"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">Email Verification Code</b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Divider-->
          <div class="kt-login__divider">
            <div class="kt-divider">
              <span></span>
              <span
                class="kt-link kt-login__signup-link"
                @click="verificationEmailBtn = !verificationEmailBtn"
              >Try Alternative</span>
              <span></span>
            </div>
          </div>

          <div class="kt-login__actions">
            <span class="kt-login__signup-label" v-if="!verificationEmailBtn"></span>
            <b-button
              v-if="verificationEmailBtn"
              type="button"
              @click="requestEmailVerificationCode"
              class="btn btn-warning btn-elevate"
              :disabled="emailVerificationMailRequested"
            >
              {{
              emailVerificationMailRequested
              ? "You can request again after 1 minute"
              : "Request Email Verification"
              }}
            </b-button>

            <b-button
              type="button"
              @click="verifyMultiAuthCode"
              id="verify_btn"
              class="btn btn-primary btn-elevate"
            >Verify</b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Multi Authenticator Form-->

        <!--begin::Google Verification Form-->
        <b-form
          class="kt-form"
          @submit.stop.prevent="verifyGoogleAuthenticator"
          v-if="googleAuthenticator"
        >
          <div role="alert" class="alert alert-danger" v-if="errors.length > 0">
            <div class="alert-text" v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>

          <b-form-group id="example-input-group-1" label label-for="example-input-1">
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              placeholder="Verification Code"
              v-model="authenticator.google.verification_code"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">Verification Code</b-form-invalid-feedback>
          </b-form-group>

          <div class="kt-login__actions">
            <span class="kt-login__signup-label"></span>
            <b-button
              type="submit"
              id="verify_btn"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
            >Verify</b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Google Verification Form-->

        <!--begin::Email Verification Form-->
        <b-form class="kt-form" v-if="emailAuthenticator">
          <div role="alert" class="alert alert-danger" v-if="errors.length > 0">
            <div class="alert-text" v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>

          <b-form-group id="example-input-group-1" label label-for="example-input-1">
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              placeholder="Email Verification Code"
              v-model="authenticator.email.verification_code"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">Verification Code</b-form-invalid-feedback>
          </b-form-group>

          <div class="kt-login__actions">
            <span class="kt-login__signup-label"></span>
            <b-button
              type="button"
              @click="verifyEmailAuthenticator"
              id="verify_btn"
              class="btn btn-primary btn-elevate"
            >Verify</b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Email  Verification Form-->
        <!-- Main Login -->
        <b-form
          class="kt-form"
          @submit.stop.prevent="onSubmit"
          v-if="
            !googleAuthenticator && !emailAuthenticator && !multiAuthenticator
          "
        >
          <div role="alert" class="alert alert-danger" v-if="errors.length > 0">
            <div class="alert-text" v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>

          <b-form-group id="example-input-group-1" label label-for="example-input-1">
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              placeholder="Your email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
              class="border"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              This information is required
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="example-input-group-2" label label-for="example-input-2">
            <b-form-input
              type="password"
              id="example-input-2"
              name="example-input-2"
              placeholder="Your password"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
              class="border"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">This information is required</b-form-invalid-feedback>
          </b-form-group>

          <router-link
            v-if="accountNotVerified"
            class="btn btn-text text-white mt-3 col-md-12"
            :to="{ name: 'verification' }"
          >Verify your account</router-link>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <router-link
              class="kt-link kt-login__link-forgot"
              :to="{ name: 'forgot-password' }"
            >{{ $t("AUTH.FORGOT.TITLE") }}</router-link>

            <b-button
              type="submit"
              id="login_btn"
              class="btn btn-primary btn-elevate kt-login__btn-primary text-white"
            >{{ $t("AUTH.LOGIN.BUTTON") }}</b-button>
          </div>
          <!--end::Action-->
        </b-form>

        <!--begin::Divider-->
       <!--  <div class="kt-login__divider">
          <div class="kt-divider">
            <span></span>
            <span>OR</span>
            <span></span>
          </div>
        </div> -->
        <!--end::Divider-->

        <!--begin::Options-->
 <!--        <div class="kt-login__options">
          <a href="#" class="btn btn-primary kt-btn" @click="AuthProvider('facebook')">
            <i class="fab fa-facebook-f"></i>
            Facebook
          </a>

          <a href="#" class="btn btn-danger kt-btn" @click="AuthProvider('google')">
            <i class="fab fa-google"></i>
            Google
          </a>
        </div> -->

        <!--end::Options-->
        <div class="kt-login__head">
          <span class="kt-login__signup-label">{{ $t("AUTH.GENERAL.NO_ACCOUNT") }}</span>&nbsp;&nbsp;
          <router-link
              class="kt-link kt-login__signup-link"
              :to="{ name: 'register' }"
          >{{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}</router-link>
        </div>
      </div>
      <!--end::Signin-->
      <vue-recaptcha
          ref="invisibleRecaptcha"
          :sitekey="sitekey"
          @verify="onCaptchaVerified"
          :loadRecaptchaScript="true"
          size="invisible"
      ></vue-recaptcha>
    </div>

    <!--end::Body-->
<!--  </div>-->
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, SET_LOGIN } from "@/store/auth.module";
import ApiService from "@/common/api.service";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
/* import VueSocialauth from "vue-social-auth"; */
import VueRecaptcha from "vue-recaptcha";
import userService from "@/services/user/UserService";
const UserService = new userService();
export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      errors: [],
      accountNotVerified: false,
      googleAuthenticator: false,
      emailAuthenticator: false,
      multiAuthenticator: false,
      verificationEmailBtn: false,
      emailVerificationMailRequested: false,
      site_main_url: process.env.VUE_APP_SERVER_URL,
      authenticator: {
        google: {
          verification_code: ""
        },
        email: {
          verification_code: ""
        }
      },
      sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    redirectIfLoggedIn() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push({ name: "dashboard" });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    activateSubmitSpinner(btnId) {
      const submitButton = document.getElementById(btnId);
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );
    },

    deactivateSubmitSpinner(btnId, seconds) {
      const submitButton = document.getElementById(btnId);
      setTimeout(() => {
        submitButton.classList.remove(
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );
      }, seconds * 1000);
    },

    setLogin(data) {
      this.$store
        .dispatch(LOGIN, data)
        // go to which page after successfully login
        .then(() => {
          let url = localStorage.getItem("rt");
          if (url != undefined && url != null) {
            localStorage.removeItem("rt");
            window.location.href = url;
          } else {
            this.$router.push({ name: "dashboard" });
          }
        });
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
    },

    onCaptchaVerified: function(recaptchaToken) {
      this.activateSubmitSpinner("login_btn");
      // set spinner to submit button
      UserService.checkVerificationEnabled(this.form)
        .then(res => {
          this.errors = [];
          this.accountNotVerified = false;
          this.deactivateSubmitSpinner("login_btn", 1);
          if (
            res.data.data.is_google_two_factor_authentication_enabled &&
            res.data.data.is_email_authentication_enabled
          ) {
            this.multiAuthenticator = true;
          } else if (
            res.data.data.is_google_two_factor_authentication_enabled
          ) {
            this.googleAuthenticator = true;
          } else if (res.data.data.is_email_authentication_enabled) {
            this.emailAuthenticator = true;
          } else {
            this.setLogin(this.form);
          }
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          if (response.data.status == "NOT_VERIFIED") {
            this.accountNotVerified = true;
          }
          this.deactivateSubmitSpinner("login_btn", 1);
        });
    },

    verifyMultiAuthCode() {
      if (this.verificationEmailBtn) this.verifyEmailAuthenticator();
      else this.verifyGoogleAuthenticator();
    },
    verifyGoogleAuthenticator() {
      if (this.authenticator.google.verification_code == "") {
        this.errors = [];
        this.errors.push("Please enter a verification code.");
      } else {
        this.errors = [];
        let data = this.form;
        data.verification_code = this.authenticator.google.verification_code;
        UserService.verifyGoogleVerificationCode(data)
          .then(res => {
            this.errors = [];
            this.authenticator.google.verification_code = "";
            this.deactivateSubmitSpinner("verify_btn", 1);
            this.googleAuthenticator = false;
            this.setLogin(this.form);
          })
          .catch(({ response }) => {
            this.errors = response.data.errors;
            this.deactivateSubmitSpinner("verify_btn", 1);
          });
      }

      setTimeout(() => {
        this.errors = [];
      }, 2000);
    },

    verifyEmailAuthenticator() {
      if (this.authenticator.email.verification_code == "") {
        this.errors = [];
        this.errors.push("Please enter a verification code.");
      } else {
        this.errors = [];
        let data = this.form;
        data.verification_code = this.authenticator.email.verification_code;
        UserService.verifyEmailVerificationCode(data)
          .then(res => {
            this.errors = [];
            this.authenticator.email.verification_code = "";
            this.setLogin(this.form);
          })
          .catch(({ response }) => {
            this.errors = response.data.errors;
            this.deactivateSubmitSpinner("verify_btn", 1);
          });
      }

      setTimeout(() => {
        this.errors = [];
      }, 2000);
    },

    requestEmailVerificationCode() {
      let data = this.form;
      UserService.requesEmailVerificationCode(data)
        .then(res => {
          this.errors = [];
          this.emailVerificationMailRequested = true;
        })
        .catch(({ response }) => {
          this.emailVerificationMailRequested = false;
          this.errors = response.data.errors;
        });
      setTimeout(() => {
        this.emailVerificationMailRequested = false;
      }, 60000);
    },

    AuthProvider(provider) {
      var self = this;
      this.$auth
        .authenticate(provider)
        .then(response => {
          self.SocialLogin(provider, response);
        })
        .catch(err => {});
    },

    SocialLogin(provider, response) {
      ApiService.get("/sanctum/csrf-cookie").then(() => {
        this.$http
          .post("/sociallogin/" + provider, response)
          .then(response => {
            this.$store
              .dispatch(SET_LOGIN, response.data)
              .then(() => this.$router.push({ name: "dashboard" }));
          })
          .catch(err => {});
      });
    }
  },
  computed: {
    ...mapState({}),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-1.jpg";
    }
  },
  mounted: function() {
    let url = this.$route.query.redirect_from;
    if (url != undefined && url != null) {
      localStorage.setItem("rt", url);
    }
    this.redirectIfLoggedIn();
  }
};
</script>
